<template>
  <div>
    <div class="mx-4" style="min-height: 80vh" v-if="false">
      <where-to-stay-banner v-if="mazatlan" />
      <v-row align="center" class="px-3 mt-1 mb-2">
        <h1>{{ "whereToStay_label" | localize }}</h1>
        <v-radio-group
          hide-details
          v-model="row"
          row
          mandatory
          class="ml-5 mt-1"
        >
          <v-radio
            :label="'city_passes_all' | localize"
            value="28,183,384,45, 196, 267,1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417"
          ></v-radio>
          <!-- Joel corrections -->
          <v-radio
            :label="'where_to_stay_label_stay' | localize"
            value="28,183,384"
          ></v-radio>
          <v-radio
            :label="'where_to_stay_label_eat' | localize"
            value="45,196,267"
          ></v-radio>
          <!-- Joel corrections -->
          <v-radio
            :label="'where_to_stay_label_shop' | localize"
            value="1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417"
          ></v-radio>
        </v-radio-group>
        <v-divider class="mt-3 ml-3 d-none d-md-block" />
      </v-row>
      <v-row align="center" class="mt-3">
        <v-divider class="pink darken-1" />
        <h1 class="text-center text-md-left mx-3 heading">
          {{ "where_to_stay_heading1_part1" | localize }}
          <span style="color: #e62076">
            {{
              row == "28,183,384"
                ? "where_to_stay_label_stay"
                : row == "45,196,267"
                ? "where_to_stay_label_eat"
                : row ==
                  "1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417"
                ? "where_to_stay_label_shop"
                : "where_to_stay_label_variables" | localize
            }}
          </span>
          {{
            mazatlan
              ? "where_to_stay_in_Mazatlan_label"
              : "where_to_stay_heading1_part2" | localize
          }}
        </h1>
        <v-divider class="pink darken-1" />
      </v-row>
      <div
        v-if="showLoader"
        class="d-flex align-center"
        style="min-height: 80vh"
      >
        <v-progress-circular
          v-if="showLoader"
          class="mx-auto"
          indeterminate
          color="#e62076"
        ></v-progress-circular>
      </div>
      <div v-for="paragraph in paragraphs" :key="paragraph.key" class="my-4">
        <v-row justify="start">
          <!-- Discount field for MOBILE SCREEN -->
          <v-col v-if="$vuetify.breakpoint.xs && (isMobile || mazatlan)">
            <v-row no-gutters justify="end" align="start">
              <div class="discountIcon">
                <v-col class="px-0 py-0">
                  <img style="margin-top: 8px" src="@/assets/cartIcon.svg" />
                  <p
                    style="
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 2px;
                      color: #000000;
                    "
                  >
                    {{ paragraph.discount_type == "Selected" ? "Some" : "All" }}
                    <br v-if="paragraph.discount_type == 'All'" />
                    <span
                      style="
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        color: #000000;
                      "
                      >-{{ parseInt(paragraph.discount_amount_min) }}%</span
                    >
                  </p>
                </v-col>
              </div>
              <div
                style="
                  width: 54px;
                  height: 18px;
                  background: #fffac9;
                  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
                  border-radius: 4px;
                  margin-top: 52px;
                  position: absolute;
                "
              ></div>
            </v-row>
          </v-col>
          <!-- /Discount field for MOBILE SCREEN -->
          <v-col class="col-12 col-md-3">
            <v-img
              class="mx-auto"
              width="220px"
              height="220px"
              style="border-radius: 10%; object-fit: cover"
              v-bind:src="paragraph.shop_logo"
              v-if="paragraph.shop_logo"
            />
            <v-img
              class="mx-auto"
              style="border-radius: 10%; object-fit: cover"
              width="220px"
              height="220px"
              v-else
              src="@/assets/IoP.png"
              alt="no img"
            />
          </v-col>
          <v-col class="col-12 col-md-9 px-0 text-center text-md-left">
            <!-- Discount field for OTHER SCREEN -->
            <v-row
              no-gutters
              justify="end"
              align="start"
              v-if="!$vuetify.breakpoint.xs && (isMobile || mazatlan)"
            >
              <div class="discountIcon">
                <v-col class="px-0 py-0">
                  <img style="margin-top: 8px" src="@/assets/cartIcon.svg" />
                  <p
                    style="
                      font-weight: 300;
                      font-size: 12px;
                      line-height: 2px;
                      color: #000000;
                    "
                  >
                    {{ paragraph.discount_type == "Selected" ? "Some" : "All" }}
                    <br v-if="paragraph.discount_type == 'All'" />
                    <span
                      style="
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        color: #000000;
                      "
                      >-{{ parseInt(paragraph.discount_amount_min) }}%</span
                    >
                  </p>
                </v-col>
              </div>
              <div
                style="
                  width: 54px;
                  height: 18px;
                  background: #fffac9;
                  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
                  border-radius: 4px;
                  margin-top: 52px;
                  position: absolute;
                "
              ></div>
            </v-row>
            <!-- /Discount field for OTHER SCREEN -->
            <h2>
              <span style="text-decoration: none; color: #e62076">
                {{ paragraph.shop_name }}
              </span>
            </h2>
            <div class="mb-2">
              <v-row no-gutters align="center">
                <a
                  :href="`https://www.google.com/maps/search/?api=1&query=${paragraph.geo_lat},${paragraph.geo_lon}`"
                  target="_blank"
                  class="pointer"
                  style="text-decoration: none; color: black; display: flex"
                >
                  <img src="@/assets/Vector.svg" class="mr-2" />
                  <v-col
                    class="pa-0"
                    cols="12"
                    xl="10"
                    lg="10"
                    md="10"
                    sm="10"
                    style="text-align: left"
                  >
                    <span class="font-weight-bold">
                      <u>{{ paragraph.address }}</u>
                    </span>
                  </v-col></a
                >
              </v-row>
            </div>
            <div class="mb-2">
              <v-row no-gutters align="center">
                <a
                  class="pointer"
                  :href="`tel: ${
                    '+' + paragraph.phone_code + paragraph.phone_number
                  }`"
                  style="text-decoration: none; color: black"
                >
                  <img src="@/assets/Phone.svg" class="mr-2" />
                  <span class="font-weight-bold">
                    <u>
                      {{ "+" + paragraph.phone_code + paragraph.phone_number }}
                    </u>
                  </span></a
                >
              </v-row>
            </div>
            <div class="mb-2">
              <v-row no-gutters align="center">
                <a
                  :href="`mailto:${paragraph.shop_email}`"
                  style="text-decoration: none; color: black; cursor: pointer"
                >
                  <img src="@/assets/Mail.svg" class="mr-2" />
                  <span class="font-weight-bold">{{
                    paragraph.shop_email
                  }}</span>
                </a>
              </v-row>
            </div>
            <div
              class="mb-2"
              v-if="paragraph.shop_website"
              id="permalink_section"
            >
              <v-row no-gutters align="center">
                <a
                  :href="paragraph.shop_website"
                  target="_blank"
                  class="pointer"
                >
                  <v-row no-gutters align="center">
                    <img src="@/assets/Web.svg" class="mr-2" />
                    <v-col
                      cols="10"
                      xl="11"
                      lg="11"
                      md="11"
                      sm="10"
                      style="text-align: left"
                    >
                      <span class="font-weight-bold">
                        {{ paragraph.shop_website }}
                      </span></v-col
                    >
                  </v-row>
                </a>
              </v-row>
            </div>
            <span
              class="mt-2 black--text"
              style="text-align: justify; display: block"
            >
              {{ paragraph.shop_description }}
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      class="d-flex align-center justify-center font-weight-bold"
      style="min-height: 80vh; font-size: 22px; color: rgb(230, 32, 118)"
      v-if="true"
    >
      {{ "coming_soon_label" | localize }}
    </div>
  </div>
</template>

<script>
import WhereToStayService from "../../requests/WhereToStay/WhereToStayService";
import whereToStayBanner from "../UI/Banners/whereToStayBanner.vue";
import mobile from "@/mixins/mobileMixin";
import cityMixin from "@/mixins/cityMixin";

export default {
  components: { whereToStayBanner },
  mixins: [mobile, cityMixin],
  data: () => ({
    paragraphs: [],
    /*[
      {
        id: 1,
        title: "DoubleTree by Hilton Guadalajara Centro Historico",
        phone:"+523339425200",
        mail:"hotel@hilton.com",
        site:"hilton.com",
        address:
          "C. Colón 73, Zona Centro, 44100 Guadalajara, Jal., Mexico",
        nearestStop: "Closest Bus/Metro stop: Linea 3-Acuario",
        openHours: "Open Hours: Mon-Sun 8:00-19:00 Sat-Sun 11:00-16:00",
        description:
          "Come learn, have fun and more, in one of the most modern and innovative conservation centers. Meet amazing species of the marine and terrestrial world in a different way while you have an incredible time with all the activities you can do with us. Your access already includes most of them, enjoy with your family and live the Michin Experience.",
      },
      {
        id: 2,
        title: "Wyndham Garden Guadalajara Acueducto",
        phone:"+523339425200",
        mail:"hotel@hilton.com",
        site:"hilton.com",
        address:
          "Av. Mariano de la Bárcena #990, Zona Centro, Guadalajara, Jalisco, México.",
        nearestStop: "Closest Bus/Metro stop: Linea 3-Acuario",
        openHours: "Open Hours: Mon-Sun 8:00-19:00 Sat-Sun 11:00-16:00",
        description:
          "Come learn, have fun and more, in one of the most modern and innovative conservation centers. Meet amazing species of the marine and terrestrial world in a different way while you have an incredible time with all the activities you can do with us. Your access already includes most of them, enjoy with your family and live the Michin Experience.",
      },
      {
        id: 3,
        title: "Hyatt Regency Andares Guadalajara",
        phone:"+523339425200",
        mail:"hotel@hilton.com",
        site:"hilton.com",
        address:
          "Av. Mariano de la Bárcena #990, Zona Centro, Guadalajara, Jalisco, México.",
        nearestStop: "Closest Bus/Metro stop: Linea 3-Acuario",
        openHours: "Open Hours: Mon-Sun 8:00-19:00 Sat-Sun 11:00-16:00",
        description:
          "Come learn, have fun and more, in one of the most modern and innovative conservation centers. Meet amazing species of the marine and terrestrial world in a different way while you have an incredible time with all the activities you can do with us. Your access already includes most of them, enjoy with your family and live the Michin Experience.",
      },
      {
        id: 4,
        title: "Holiday Inn Express Guadalajara Iteso, an IHG Hotel",
        phone:"+523339425200",
        mail:"hotel@hilton.com",
        site:"hilton.com",
        address:
          "Av. Mariano de la Bárcena #990, Zona Centro, Guadalajara, Jalisco, México.",
        nearestStop: "Closest Bus/Metro stop: Linea 3-Acuario",
        openHours: "Open Hours: Mon-Sun 8:00-19:00 Sat-Sun 11:00-16:00",
        description:
          "Come learn, have fun and more, in one of the most modern and innovative conservation centers. Meet amazing species of the marine and terrestrial world in a different way while you have an incredible time with all the activities you can do with us. Your access already includes most of them, enjoy with your family and live the Michin Experience.",
      },
    ],*/
    row: "28,183,384,45, 196, 267,1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417",
    showLoader: true,
  }),
  mounted() {
    if (this.$route.params.selected == "all") {
      this.row =
        "28,183,384,45, 196, 267,1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417";
    } else if (this.$route.params.selected == "stay") {
      this.row = "28,183,384";
    } else if (this.$route.params.selected == "eat") {
      this.row = "45,196,267";
    } else if (this.$route.params.selected == "shop") {
      this.row =
        "1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417";
    } else {
      this.row =
        "28,183,384,45, 196, 267,1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417";
    }
    this.getBusinessFromCategory();
  },
  methods: {
    async getBusinessFromCategory() {
      let response = await WhereToStayService.getBusinessFromCategory(this.row);
      this.paragraphs = response.result;
      this.showLoader = false;
    },
  },
  watch: {
    row: {
      deep: true,
      handler() {
        this.showLoader = true;
        if (
          this.row ==
          "28,183,384,45, 196, 267,1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417"
        ) {
          this.$router.push({
            name: "WhereToStay",
            params: { selected: "all" },
          });
        } else if (this.row == "28,183,384") {
          this.$router.push({
            name: "WhereToStay",
            params: { selected: "stay" },
          });
        } else if (this.row == "45,196,267") {
          this.$router.push({
            name: "WhereToStay",
            params: { selected: "eat" },
          });
        } else if (
          this.row ==
          "1, 6, 11, 18, 19, 22, 32, 34, 39, 46, 49, 50, 55, 70, 71,72, 99, 100, 133, 141, 143, 144, 145, 153, 156, 160, 165, 166, 175, 184, 199, 202, 217, 277, 278, 300, 310, 332, 360, 361, 373, 374, 409, 410, 417"
        ) {
          this.$router.push({
            name: "WhereToStay",
            params: { selected: "shop" },
          });
        } else {
          this.$router.push({
            name: "WhereToStay",
            params: { selected: "all" },
          });
        }
        this.getBusinessFromCategory();
      },
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .heading {
    font-size: 20px !important;
  }
}
.pointer {
  cursor: pointer;
}
.discountIcon {
  position: absolute;
  background: url("../../assets/whereToStayDiscountIcon.svg");
  background-repeat: no-repeat;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 54px;
  height: 68px;
  text-align: center;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
#permalink_section {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
</style>