<template>
  <v-main>
    <v-container>
        <home-page />
    </v-container>
  </v-main>
</template>

<script>
import HomePage from '@/components/WhereToStay/WhereToStayList.vue';
export default {
    components: {
        HomePage,
    }
}
</script>