<template>
  <v-row no-gutters align="center" justify="center" class="mb-5">
    <span
      style="position: absolute; font-weight: 600; color: #444444"
      :style="
        $vuetify.breakpoint.xs
          ? 'font-size: 14.4px;line-height: 23px; padding: 30px;'
          : 'font-size:24px;line-height: 38px;'
      "
      >{{ "where_to_stay_businesses1" | localize }}<br /><u
        style="font-weight: 600; color: #e62076; cursor: pointer"
        :style="
          $vuetify.breakpoint.xs
            ? 'font-size: 18.4px;line-height: 23px; margin-left: 60px;'
            : 'font-size:28px;line-height: 38px; margin-left: 120px;'
        "
        ><a
          href="https://business.hoozaround.com/login"
          target="_blank"
          style="text-decoration: none; color: #e62076 !important"
          >{{ "where_to_stay_businesses2" | localize }}&nbsp;<strong>{{
            mazatlan
              ? "label_mazatlan_pass"
              : "where_to_stay_businesses3" | localize
          }}</strong></a
        >
      </u>
    </span>
    <img
      :width="$vuetify.breakpoint.xs ? '360px' : '100%'"
      :height="$vuetify.breakpoint.xs ? '120px' : ''"
      :src="
        $vuetify.breakpoint.xs
          ? require('../../../assets/whereToStayJoinBackgroundMobile.png')
          : require('../../../assets/whereToStayJoinBackground.svg')
      "
      class="backgroundImage"
    />
  </v-row>
</template>

<script>
import cityMixin from '@/mixins/cityMixin'
export default {
  mixins: [cityMixin]
};
</script>

<style>
.backgroundImage {
  box-shadow: 5px 0px 10px 1px silver;
  border-radius: 40px;
}
</style>